import React from 'react';

function About() {
  return (
    <div id="aboutPage" className="container my-5 pt-5">
      <div className="row gx-0 flex-row-reverse">
        <h3 className="text-center pb-4">about me</h3>

        <div className="col-12 col-xl-5 p-1 pb-4 p-xl-5 d-flex justify-content-center">
          <svg className="about-svgs" width="315" height="330" viewBox="0 0 518 483" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
              <g id="svg-me" clipPath="url(#clip0_47_2)">
                <g id="Group">
                  <path id="Vector" d="M316.831 260.889H220.169V187.479C220.169 161.313 241.139 140.029 267.252 139.371C267.667 139.36 268.082 139.355 268.497 139.355H268.503C295.19 139.355 316.831 160.899 316.831 187.479V260.889Z" fill="#714136" />
                  <path id="Vector_2" d="M316.831 264.152H220.169C218.36 264.152 216.892 262.691 216.892 260.889V187.479C216.892 173.983 222.106 161.233 231.574 151.579C241.024 141.944 253.665 136.449 267.17 136.109C267.61 136.098 268.053 136.092 268.498 136.092C296.958 136.092 320.108 159.144 320.108 187.479V260.889C320.107 262.691 318.64 264.152 316.831 264.152ZM223.445 257.627H313.554V187.479C313.554 162.742 293.344 142.617 268.503 142.617C268.111 142.617 267.724 142.622 267.338 142.633C255.547 142.93 244.512 147.726 236.262 156.139C227.997 164.567 223.445 175.697 223.445 187.479V257.627V257.627Z" fill="#333333" />
                  <g id="Group_2">
                    <path id="Vector_3" d="M295.452 246.735C295.556 246.762 295.665 246.784 295.769 246.811C301.481 248.187 306.794 250.579 311.512 253.777C324.285 262.434 332.673 277.034 332.673 293.576V339.737H306.985H230.009H204.327V293.576C204.327 277.034 212.71 262.434 225.482 253.777C230.201 250.579 235.514 248.187 241.226 246.811C241.33 246.784 241.439 246.762 241.543 246.735" fill="#6BC9A7" />
                    <path id="Vector_4" d="M230.009 272.309V339.737H204.327V293.576C204.327 277.034 212.71 262.434 225.482 253.777C228.377 259.323 230.009 265.626 230.009 272.309Z" fill="#FFD3AE" />
                    <path id="Vector_5" d="M332.673 293.576V339.737H306.985V272.309C306.985 265.626 308.618 259.323 311.512 253.777C324.285 262.434 332.673 277.034 332.673 293.576Z" fill="#FFD3AE" />
                    <path id="Vector_6" d="M337.034 339.737H330.481V296.947C330.481 282.901 324.108 269.67 312.538 259.69C300.835 249.593 285.245 244.032 268.637 244.032C234.536 244.032 206.793 267.77 206.793 296.947V339.737H200.24V296.947C200.24 264.172 230.923 237.507 268.637 237.507C286.821 237.507 303.935 243.634 316.828 254.758C329.857 265.996 337.034 280.979 337.034 296.947V339.737Z" fill="#333333" />
                    <path id="Vector_7" d="M310.262 339.737H303.709V272.309C303.709 265.34 305.402 258.411 308.605 252.273L314.42 255.281C311.66 260.569 310.262 266.298 310.262 272.309V339.737V339.737Z" fill="#333333" />
                    <path id="Vector_8" d="M321.751 332.124C319.942 332.124 318.475 330.663 318.475 328.862V289.166C318.475 287.364 319.942 285.903 321.751 285.903C323.561 285.903 325.028 287.364 325.028 289.166V328.862C325.028 330.663 323.561 332.124 321.751 332.124Z" fill="white" />
                    <g id="Group_3">
                      <path id="Vector_9" opacity="0.44" d="M228.18 260.302C219.874 268.943 214.768 280.667 214.768 293.576V338.65H204.327V293.576C204.327 277.034 212.71 262.434 225.482 253.777C226.564 255.854 227.47 258.035 228.18 260.302Z" fill="#333333" />
                    </g>
                    <path id="Vector_10" d="M233.286 339.737H226.733V272.309C226.733 266.298 225.334 260.568 222.575 255.281L228.39 252.273C231.592 258.411 233.286 265.34 233.286 272.309V339.737Z" fill="#333333" />
                    <g id="Group_4">
                      <path id="Vector_11" d="M340.31 343H196.815C195.006 343 193.539 341.539 193.539 339.737C193.539 337.936 195.006 336.475 196.815 336.475H340.31C342.119 336.475 343.586 337.936 343.586 339.737C343.586 341.539 342.119 343 340.31 343Z" fill="#333333" />
                      <path id="Vector_12" d="M357.785 343H351.778C349.969 343 348.501 341.539 348.501 339.737C348.501 337.936 349.969 336.475 351.778 336.475H357.785C359.594 336.475 361.061 337.936 361.061 339.737C361.061 341.539 359.594 343 357.785 343Z" fill="#333333" />
                      <path id="Vector_13" d="M185.222 343H179.215C177.406 343 175.939 341.539 175.939 339.737C175.939 337.936 177.406 336.475 179.215 336.475H185.222C187.032 336.475 188.499 337.936 188.499 339.737C188.499 341.539 187.032 343 185.222 343Z" fill="#333333" />
                    </g>
                  </g>
                  <g id="Group_5">
                    <g id="Group_6">
                      <path id="Vector_14" d="M224.503 201.558C232.915 201.558 239.733 194.768 239.733 186.392C239.733 178.016 232.915 171.226 224.503 171.226C216.092 171.226 209.273 178.016 209.273 186.392C209.273 194.768 216.092 201.558 224.503 201.558Z" fill="#FFD3AE" />
                      <path id="Vector_15" d="M312.496 201.558C320.908 201.558 327.727 194.768 327.727 186.392C327.727 178.016 320.908 171.226 312.496 171.226C304.085 171.226 297.266 178.016 297.266 186.392C297.266 194.768 304.085 201.558 312.496 201.558Z" fill="#FFD3AE" />
                    </g>
                    <g id="Group_7">
                      <path id="Vector_16" d="M224.504 204.82C214.298 204.82 205.996 196.553 205.996 186.392C205.996 176.23 214.298 167.963 224.504 167.963C234.708 167.963 243.01 176.23 243.01 186.392C243.01 196.553 234.708 204.82 224.504 204.82ZM224.504 174.488C217.912 174.488 212.549 179.828 212.549 186.392C212.549 192.955 217.912 198.295 224.504 198.295C231.095 198.295 236.457 192.955 236.457 186.392C236.457 179.828 231.095 174.488 224.504 174.488Z" fill="#333333" />
                      <path id="Vector_17" d="M312.496 204.82C302.292 204.82 293.99 196.553 293.99 186.392C293.99 176.23 302.292 167.963 312.496 167.963C322.701 167.963 331.003 176.23 331.003 186.392C331.003 196.553 322.701 204.82 312.496 204.82ZM312.496 174.488C305.905 174.488 300.543 179.828 300.543 186.392C300.543 192.955 305.904 198.295 312.496 198.295C319.088 198.295 324.45 192.955 324.45 186.392C324.45 179.828 319.088 174.488 312.496 174.488Z" fill="#333333" />
                    </g>
                  </g>
                  <path id="Vector_18" d="M295.455 238.578C287.755 243.738 278.482 246.751 268.5 246.751C258.518 246.751 249.245 243.738 241.545 238.578C241.442 238.507 241.332 238.437 241.229 238.361C228.516 229.693 220.172 215.13 220.172 198.627V186.392V158.387C220.172 131.809 241.809 110.263 268.5 110.263C295.191 110.263 316.828 131.809 316.828 158.387V186.392V198.627C316.828 215.13 308.484 229.693 295.771 238.361C295.668 238.437 295.558 238.507 295.455 238.578Z" fill="#FFD3AE" />
                  <g id="Group_8">
                    <path id="Vector_19" d="M268.5 224.462C265.841 224.462 263.182 224.183 260.543 223.625C258.772 223.251 257.641 221.519 258.017 219.757C258.392 217.994 260.132 216.869 261.901 217.242C266.281 218.166 270.719 218.166 275.099 217.242C276.868 216.868 278.609 217.994 278.983 219.757C279.359 221.52 278.228 223.252 276.458 223.625C273.818 224.183 271.159 224.462 268.5 224.462Z" fill="#F7774B" />
                  </g>
                  <g id="Group_9" opacity="0.44">
                    <path id="Vector_20" opacity="0.44" d="M268.776 231.525H268.224C267.018 231.525 266.04 230.551 266.04 229.35C266.04 228.149 267.018 227.175 268.224 227.175H268.776C269.982 227.175 270.96 228.149 270.96 229.35C270.96 230.551 269.982 231.525 268.776 231.525Z" fill="#333333" />
                  </g>
                  <g id="Group_10">
                    <g id="Group_11">
                      <path id="Vector_21" d="M284.078 183.354C282.59 183.354 281.244 182.338 280.89 180.834C280.478 179.079 281.573 177.324 283.335 176.914C288.634 175.68 294.236 175.679 299.536 176.914C301.298 177.324 302.392 179.079 301.981 180.833C301.569 182.587 299.809 183.679 298.044 183.267C293.72 182.26 289.15 182.261 284.827 183.267C284.576 183.326 284.325 183.354 284.078 183.354Z" fill="#333333" />
                    </g>
                  </g>
                  <path id="Vector_22" opacity="0.44" d="M258.474 238.578C263.946 242.243 270.215 244.826 276.964 246.017C274.217 246.501 271.389 246.751 268.5 246.751C258.518 246.751 249.245 243.739 241.545 238.578C241.442 238.507 241.332 238.437 241.229 238.361C228.516 229.693 220.172 215.13 220.172 198.627V186.392C220.172 110.806 250.146 111.73 250.146 113.302L244.476 121.897C236.086 134.615 236.117 150.812 243.952 163.35C245.276 165.468 249.496 172.754 249.424 174.425C249.16 180.564 244.399 186.04 237.771 186.374C237.549 186.386 237.325 186.391 237.1 186.391V198.626C237.1 215.13 245.444 229.692 258.157 238.36C258.261 238.437 258.37 238.507 258.474 238.578Z" fill="#333333" />
                  <g id="Group_12">
                    <g id="Group_13">
                      <g id="Group_14">
                        <g id="Group_15">
                          <path id="Vector_23" d="M249.748 196.791C252.163 196.791 254.121 194.842 254.121 192.436C254.121 190.031 252.163 188.081 249.748 188.081C247.332 188.081 245.374 190.031 245.374 192.436C245.374 194.842 247.332 196.791 249.748 196.791Z" fill="#333333" />
                        </g>
                        <path id="Vector_24" d="M241.376 196.293C240.539 196.293 239.702 195.976 239.063 195.341C237.781 194.068 237.778 192.002 239.056 190.727C242.416 187.371 247.547 186.515 251.821 188.596C253.447 189.387 254.121 191.341 253.326 192.959C252.531 194.578 250.57 195.25 248.944 194.458C247.187 193.603 245.078 193.955 243.697 195.334C243.056 195.973 242.216 196.293 241.376 196.293Z" fill="#333333" />
                      </g>
                    </g>
                    <g id="Group_16">
                      <g id="Group_17">
                        <g id="Group_18">
                          <path id="Vector_25" d="M287.253 196.791C289.668 196.791 291.626 194.842 291.626 192.436C291.626 190.031 289.668 188.081 287.253 188.081C284.837 188.081 282.879 190.031 282.879 192.436C282.879 194.842 284.837 196.791 287.253 196.791Z" fill="#333333" />
                        </g>
                        <path id="Vector_26" d="M295.624 196.293C294.783 196.293 293.943 195.973 293.303 195.334C291.922 193.954 289.814 193.603 288.057 194.458C286.431 195.249 284.469 194.578 283.675 192.959C282.88 191.341 283.554 189.387 285.18 188.596C289.454 186.515 294.585 187.371 297.945 190.727C299.222 192.003 299.219 194.069 297.937 195.341C297.297 195.975 296.46 196.293 295.624 196.293Z" fill="#333333" />
                      </g>
                    </g>
                  </g>
                  <g id="Group_19">
                    <path id="Vector_27" d="M264.709 209.774C262.899 209.774 261.432 208.313 261.432 206.511V188.606C261.432 187.143 260.605 185.781 259.325 185.14C255.398 183.171 248.662 181.25 238.88 183.284C237.108 183.653 235.374 182.522 235.003 180.758C234.633 178.993 235.769 177.265 237.54 176.897C249.175 174.478 257.412 176.875 262.272 179.312C265.796 181.079 267.985 184.64 267.985 188.606V206.511C267.985 208.313 266.518 209.774 264.709 209.774Z" fill="#333333" />
                  </g>
                  <path id="Vector_28" d="M221.425 183.51L220.988 183.673H220.169V158.387C220.169 132.22 241.139 110.937 267.252 110.279L268.497 110.806V149.719C256.325 164.804 240.15 176.587 221.425 183.51Z" fill="#714136" />
                  <path id="Vector_29" opacity="0.44" d="M268.497 110.806V115.874C253.229 123.96 242.837 139.964 242.837 158.387V172.803C236.18 177.104 229.01 180.709 221.425 183.51L220.988 183.673H220.169V158.387C220.169 132.22 241.139 110.937 267.252 110.279L268.497 110.806Z" fill="#333333" />
                  <path id="Vector_30" d="M315.575 183.51C296.844 176.587 280.669 164.804 268.497 149.719V110.806L267.252 110.279C267.667 110.268 268.082 110.263 268.497 110.263H268.503C295.19 110.263 316.831 131.807 316.831 158.387V183.673H316.006L315.575 183.51Z" fill="#714136" />
                  <path id="Vector_31" d="M307.002 166.272C305.192 166.272 303.725 164.811 303.725 163.009V158.387C303.725 146.76 297.933 135.903 288.231 129.346C286.734 128.334 286.344 126.305 287.36 124.814C288.376 123.324 290.414 122.934 291.912 123.947C303.412 131.72 310.278 144.595 310.278 158.387V163.009C310.278 164.811 308.811 166.272 307.002 166.272Z" fill="white" />
                  <path id="Vector_32" d="M281.291 125.489C280.929 125.489 280.561 125.429 280.2 125.302C279.312 124.989 278.4 124.71 277.49 124.47C275.741 124.01 274.697 122.225 275.159 120.483C275.621 118.741 277.413 117.702 279.163 118.161C280.244 118.445 281.327 118.778 282.382 119.149C284.088 119.749 284.983 121.612 284.381 123.311C283.906 124.652 282.641 125.489 281.291 125.489Z" fill="white" />
                  <path id="Vector_33" d="M268.497 249.47C258.191 249.47 248.239 246.451 239.719 240.741C239.592 240.659 239.453 240.566 239.32 240.468C225.274 230.863 216.893 215.024 216.893 198.083V157.844C216.893 144.347 222.107 131.598 231.575 121.944C241.024 112.308 253.666 106.814 267.17 106.474C267.609 106.462 268.053 106.456 268.497 106.456C296.958 106.456 320.108 129.509 320.108 157.843V198.083C320.108 215.036 311.717 230.884 297.661 240.485C297.585 240.539 297.507 240.592 297.43 240.643L297.374 240.682C297.342 240.704 297.319 240.72 297.287 240.741C288.766 246.451 278.811 249.47 268.497 249.47ZM268.503 112.982C268.111 112.982 267.725 112.986 267.339 112.997C255.548 113.294 244.512 118.09 236.262 126.503C227.997 134.931 223.446 146.061 223.446 157.843V198.083C223.446 212.893 230.786 226.741 243.082 235.125C243.136 235.161 243.188 235.199 243.239 235.239L243.326 235.294C243.338 235.302 243.365 235.32 243.377 235.328C250.812 240.311 259.5 242.945 268.497 242.945C277.488 242.945 286.165 240.319 293.593 235.351C293.653 235.309 293.715 235.267 293.779 235.225L293.842 235.182C293.872 235.16 293.892 235.146 293.923 235.125C306.215 226.743 313.554 212.896 313.554 198.083V157.843C313.555 133.106 293.344 112.982 268.503 112.982Z" fill="#333333" />
                  <path id="Vector_34" d="M222.139 186.728L219.838 180.618L220.275 180.455C238.195 173.83 253.984 162.496 265.944 147.676C266.566 146.905 267.505 146.457 268.498 146.457C269.491 146.457 270.43 146.905 271.052 147.676C283.012 162.497 298.802 173.831 316.716 180.451L317.17 180.622L314.845 186.723L314.413 186.56C296.661 180 280.857 169.061 268.498 154.816C256.14 169.061 240.336 180 222.566 186.569L222.139 186.728Z" fill="#333333" />
                  <path id="Vector_35" d="M271.774 110.806H265.221V149.719H271.774V110.806Z" fill="#333333" />
                  <path id="Vector_36" d="M307.002 211.406C305.192 211.406 303.725 209.944 303.725 208.143V192.373C303.725 190.572 305.192 189.111 307.002 189.111C308.811 189.111 310.278 190.572 310.278 192.373V208.143C310.278 209.944 308.811 211.406 307.002 211.406Z" fill="white" />
                </g>
                <g id="Group_20">
                  <g id="Group_21">
                    <path id="Vector_37" opacity="0.44" d="M243.267 212.811C233.98 212.811 226.452 205.314 226.452 196.067V179.323H243.267C252.553 179.323 260.082 186.819 260.082 196.067C260.082 205.314 252.553 212.811 243.267 212.811Z" fill="#F7EADC" />
                    <path id="Vector_38" d="M243.267 216.073C232.188 216.073 223.175 207.098 223.175 196.067V179.323C223.175 177.521 224.642 176.06 226.452 176.06H243.267C254.345 176.06 263.358 185.035 263.358 196.067C263.358 207.098 254.345 216.073 243.267 216.073ZM229.728 182.585V196.066C229.728 203.5 235.801 209.547 243.266 209.547C250.731 209.547 256.804 203.5 256.804 196.066C256.804 188.633 250.731 182.585 243.266 182.585H229.728Z" fill="#FFD155" />
                  </g>
                  <g id="Group_22">
                    <path id="Vector_39" opacity="0.44" d="M294.512 212.811C303.799 212.811 311.328 205.314 311.328 196.067V179.323H294.512C285.226 179.323 277.697 186.819 277.697 196.067C277.697 205.314 285.226 212.811 294.512 212.811Z" fill="#F7EADC" />
                    <path id="Vector_40" d="M294.513 216.073C283.434 216.073 274.421 207.098 274.421 196.067C274.421 185.034 283.434 176.06 294.513 176.06H311.328C313.137 176.06 314.604 177.521 314.604 179.323V196.067C314.604 207.098 305.591 216.073 294.513 216.073ZM294.513 182.585C287.047 182.585 280.974 188.633 280.974 196.066C280.974 203.5 287.047 209.547 294.513 209.547C301.978 209.547 308.051 203.5 308.051 196.066V182.585H294.513Z" fill="#FFD155" />
                  </g>
                  <path id="Vector_41" d="M277.759 192.803H260.082V199.329H277.759V192.803Z" fill="#FFD155" />
                </g>
                <path id="Vector_42" d="M252.549 273.467C251.765 273.467 250.991 273.187 250.38 272.651C249.934 272.258 239.418 262.89 236.884 249.623C236.846 249.422 236.827 249.218 236.827 249.013L240.118 249.01L243.323 248.404C244.513 254.633 247.928 259.942 250.712 263.415C251.167 262.352 251.728 261.297 252.395 260.252C257.529 252.213 267.396 247.294 267.814 247.089C268.729 246.639 269.802 246.639 270.717 247.089C271.134 247.294 281 252.213 286.135 260.253C286.8 261.293 287.36 262.345 287.813 263.404C290.616 259.901 294.062 254.545 295.225 248.308L301.669 249.5C299.181 262.829 288.596 272.255 288.147 272.65C287.181 273.499 285.807 273.705 284.632 273.177C283.458 272.65 282.703 271.486 282.703 270.203C282.703 268.102 281.998 265.932 280.606 263.755C277.485 258.869 271.904 255.254 269.267 253.732C266.629 255.26 261.027 258.893 257.923 263.754C256.53 265.933 255.825 268.103 255.825 270.203C255.825 271.486 255.07 272.65 253.896 273.177C253.464 273.372 253.005 273.467 252.549 273.467Z" fill="#333333" />
                <path id="Vector_43" d="M269.264 282.711C267.455 282.711 265.988 281.25 265.988 279.449V250.014C265.988 248.212 267.455 246.751 269.264 246.751C271.073 246.751 272.541 248.212 272.541 250.014V279.449C272.541 281.251 271.074 282.711 269.264 282.711Z" fill="#333333" />
              </g>
              <g id="my-hobbies-svgs">
                <g id="svg-ukulele" className="about-svg" clipPath="url(#clip1_47_2)">
                  <g id="Group_23">
                    <path id="Vector_44" d="M460.88 347.738C464.256 343.411 473.971 340.442 477.568 336.845C485.201 329.212 480.564 317.349 470.387 307.172C460.209 296.994 448.347 292.357 440.713 299.99C437.116 303.587 434.148 313.302 429.82 316.678C420.936 323.609 410.543 320.481 403.688 327.336C393.087 337.937 398.085 351.204 412.22 365.339C426.355 379.474 439.621 384.471 450.222 373.87C457.077 367.015 453.95 356.623 460.88 347.738Z" fill="#F9B65F" />
                    <path id="Vector_45" opacity="0.3" d="M475.501 313.06C479.082 319.987 479.319 326.754 474.347 331.727C470.75 335.324 461.035 338.292 457.659 342.62C450.729 351.504 453.856 361.897 447.001 368.752C436.4 379.353 423.133 374.355 408.998 360.22C405.929 357.151 403.293 354.123 401.151 351.154C403.588 355.731 407.365 360.484 412.219 365.338C426.354 379.473 439.621 384.471 450.222 373.87C457.077 367.015 453.95 356.622 460.88 347.738C464.256 343.41 473.971 340.442 477.568 336.845C483.764 330.649 481.875 321.666 475.501 313.06Z" fill="#F9B65F" />
                    <path id="Vector_46" d="M508.582 278.023L499.534 268.975L447.701 320.808L456.75 329.856L508.582 278.023Z" fill="#935733" />
                    <path id="Vector_47" opacity="0.3" d="M505.454 274.897L453.621 326.729L456.749 329.857L508.582 278.024L505.454 274.897Z" fill="#935733" />
                    <path id="Vector_48" d="M447.96 339.653C453.513 339.653 458.014 335.151 458.014 329.598C458.014 324.046 453.513 319.544 447.96 319.544C442.407 319.544 437.906 324.046 437.906 329.598C437.906 335.151 442.407 339.653 447.96 339.653Z" fill="#794C2B" />
                    <path id="Vector_49" opacity="0.31" d="M447.96 337.84C452.512 337.84 456.202 334.15 456.202 329.598C456.202 325.047 452.512 321.356 447.96 321.356C443.408 321.356 439.718 325.047 439.718 329.598C439.718 334.15 443.408 337.84 447.96 337.84Z" fill="#794C2B" />
                    <path id="Vector_50" d="M447.96 333.529C450.13 333.529 451.89 331.769 451.89 329.599C451.89 327.428 450.13 325.668 447.96 325.668C445.789 325.668 444.03 327.428 444.03 329.599C444.03 331.769 445.789 333.529 447.96 333.529Z" fill="#AE9676" />
                    <path id="Vector_51" d="M420.38 345.967L431.592 357.179C432.565 358.152 434.143 358.152 435.116 357.179L436.375 355.92C437.348 354.947 437.348 353.369 436.375 352.396L425.162 341.184C424.19 340.211 422.612 340.211 421.639 341.184L420.38 342.443C419.407 343.416 419.407 344.994 420.38 345.967Z" fill="#794C2B" />
                    <path id="Vector_52" d="M496.311 286.342L491.216 281.248C489.735 279.767 489.594 277.412 490.889 275.765L502.379 261.141C503.912 259.19 506.806 259.017 508.56 260.771L516.788 268.999C518.542 270.754 518.369 273.647 516.418 275.18L501.794 286.67C500.147 287.965 497.792 287.824 496.311 286.342Z" fill="#794C2B" />
                    <path id="Vector_53" opacity="0.31" d="M516.788 268.999L516.064 268.275C515.805 268.742 515.454 269.17 515.004 269.524L500.381 281.014C498.733 282.308 496.378 282.167 494.897 280.686L490.527 276.316C489.643 277.906 489.894 279.925 491.217 281.249L496.311 286.343C497.793 287.824 500.147 287.965 501.795 286.67L516.419 275.18C518.369 273.647 518.542 270.753 516.788 268.999Z" fill="#794C2B" />
                  </g>
                </g>
                <g id="svg-controller" className="about-svg" clipPath="url(#clip2_47_2)">
                  <path id="Vector_54" d="M161.92 19.6444L161.805 18.2514C161.756 17.6521 161.326 17.0764 160.651 16.7785C158.797 15.9606 156.81 15.3478 154.717 14.9768C152.623 14.6057 150.547 14.4983 148.525 14.6295C147.789 14.6772 147.187 15.0699 146.934 15.6158L146.348 16.8845L161.92 19.6444Z" fill="#707487" />
                  <path id="Vector_55" d="M139.637 27.5635L164.552 31.9793L163.002 20.1627C162.922 19.5566 162.455 18.9919 161.759 18.7121C159.464 17.789 157.008 17.0783 154.425 16.6205C151.843 16.1628 149.292 15.9861 146.819 16.0641C146.07 16.0877 145.437 16.4576 145.154 16.9994L139.637 27.5635Z" fill="#61697A" />
                  <path id="Vector_56" d="M225.985 30.9993L225.87 29.6063C225.821 29.007 225.391 28.4314 224.716 28.1335C222.862 27.3155 220.875 26.7028 218.782 26.3317C216.688 25.9606 214.612 25.8533 212.59 25.9845C211.854 26.0322 211.252 26.4249 211 26.9708L210.414 28.2395L225.985 30.9993Z" fill="#707487" />
                  <g id="Group_24">
                    <path id="Vector_57" d="M203.703 38.9185L228.617 43.3343L227.067 31.5177C226.987 30.9116 226.52 30.3469 225.825 30.0671C223.53 29.1441 221.073 28.4333 218.491 27.9755C215.908 27.5178 213.357 27.3411 210.885 27.4191C210.135 27.4427 209.502 27.8126 209.219 28.3544L203.703 38.9185Z" fill="#61697A" />
                    <path id="Vector_58" d="M208.136 34.6177L161.867 26.4168L130.771 36.1648L124.122 73.6774C123.128 79.2882 127.246 84.7092 133.319 85.7858L133.32 85.7859C137.486 86.5243 141.679 85.0253 144.152 81.9137L162.435 58.9107C163.235 57.9036 164.592 57.4184 165.94 57.6574L193.573 62.5552C194.922 62.7941 196.029 63.7161 196.435 64.9369L205.697 92.822C206.95 96.5941 210.373 99.4429 214.539 100.181L214.54 100.181C220.614 101.258 226.344 97.5823 227.338 91.9716L233.987 54.459L208.136 34.6177Z" fill="#61697A" />
                  </g>
                  <g id="Group_25">
                    <path id="Vector_59" d="M147.141 57.7167C157.952 59.6329 168.152 53.0906 169.922 43.104C171.692 33.1174 164.362 23.4683 153.551 21.5521C142.74 19.6359 132.541 26.1782 130.771 36.1648C129.001 46.1514 136.33 55.8005 147.141 57.7167Z" fill="#8B93A6" />
                    <path id="Vector_60" d="M211.206 69.0718C222.018 70.988 232.217 64.4457 233.987 54.4591C235.757 44.4725 228.428 34.8234 217.616 32.9072C206.805 30.991 196.606 37.5333 194.836 47.5199C193.066 57.5065 200.395 67.1556 211.206 69.0718Z" fill="#8B93A6" />
                  </g>
                  <g id="Group_26">
                    <path id="Vector_61" d="M163.678 36.9109L156.559 35.6493L157.725 29.0739C157.886 28.1661 157.219 27.2887 156.237 27.1145L149.118 25.8529C148.135 25.6787 147.208 26.2736 147.047 27.1814L145.882 33.7567L138.763 32.495C137.781 32.3209 136.853 32.9157 136.692 33.8235L135.527 40.3989C135.366 41.3067 136.032 42.1841 137.015 42.3582L144.133 43.6199L142.968 50.1953C142.807 51.1031 143.473 51.9804 144.456 52.1546L151.575 53.4163C152.557 53.5905 153.485 52.9956 153.646 52.0878L154.811 45.5124L161.929 46.7741C162.912 46.9483 163.839 46.3534 164 45.4456L165.166 38.8702C165.327 37.9622 164.66 37.0851 163.678 36.9109Z" fill="#D7DEED" />
                    <path id="Vector_62" d="M227.743 48.266L220.624 47.0043L221.79 40.429C221.951 39.5212 221.284 38.6438 220.302 38.4696L213.183 37.208C212.2 37.0338 211.273 37.6286 211.112 38.5364L209.947 45.1118L202.828 43.8501C201.846 43.676 200.918 44.2708 200.757 45.1786L199.592 51.754C199.431 52.6618 200.098 53.5392 201.08 53.7134L208.199 54.975L207.033 61.5504C206.872 62.4582 207.539 63.3355 208.521 63.5097L215.64 64.7714C216.622 64.9456 217.55 64.3507 217.711 63.4429L218.876 56.8675L225.994 58.1292C226.977 58.3034 227.905 57.7085 228.066 56.8007L229.231 50.2253C229.392 49.3173 228.725 48.4402 227.743 48.266Z" fill="#D7DEED" />
                  </g>
                  <g id="Group_27">
                    <path id="Vector_63" d="M145.784 41.3692L142.247 40.7422C141.565 40.6213 141.102 40.0122 141.214 39.3819L141.683 36.7328C141.795 36.1026 142.439 35.6895 143.121 35.8104L146.659 36.4374C147.018 36.5011 147.334 36.7061 147.524 36.9989L148.684 38.7859C148.917 39.1453 148.836 39.6023 148.494 39.8598L146.79 41.1391C146.511 41.3489 146.144 41.4329 145.784 41.3692Z" fill="#707487" />
                    <path id="Vector_64" d="M155.432 37.9926L158.969 38.6196C159.652 38.7405 160.114 39.3495 160.003 39.9799L159.533 42.629C159.421 43.2592 158.778 43.6723 158.095 43.5514L154.558 42.9243C154.198 42.8607 153.883 42.6556 153.692 42.3628L152.532 40.5759C152.299 40.2165 152.38 39.7595 152.723 39.502L154.426 38.2227C154.705 38.0128 155.072 37.9288 155.432 37.9926Z" fill="#707487" />
                    <path id="Vector_65" d="M152.559 44.206L151.98 47.4736C151.868 48.1037 151.225 48.5169 150.542 48.3959L147.674 47.8876C146.992 47.7667 146.529 47.1577 146.641 46.5273L147.22 43.2597C147.279 42.9279 147.492 42.6402 147.802 42.4716L149.696 41.4431C150.077 41.2362 150.572 41.3238 150.859 41.6492L152.284 43.266C152.518 43.5312 152.618 43.8742 152.559 44.206Z" fill="#707487" />
                    <path id="Vector_66" d="M148.657 35.1556L149.236 31.888C149.348 31.2579 149.991 30.8447 150.674 30.9657L153.542 31.474C154.224 31.5949 154.687 32.204 154.575 32.8343L153.996 36.1019C153.937 36.4337 153.725 36.7215 153.414 36.89L151.52 37.9185C151.139 38.1255 150.644 38.0378 150.358 37.7125L148.932 36.0956C148.699 35.8307 148.598 35.4876 148.657 35.1556Z" fill="#707487" />
                  </g>
                  <path id="Vector_67" d="M204.931 52.7001C206.896 53.0485 208.751 51.859 209.073 50.0432C209.395 48.2275 208.062 46.4731 206.096 46.1247C204.131 45.7763 202.276 46.9658 201.954 48.7816C201.633 50.5973 202.965 52.3517 204.931 52.7001Z" fill="#FF82AF" />
                  <path id="Vector_68" d="M181.796 48.5998C184.745 49.1224 187.526 47.3381 188.009 44.6144C188.492 41.8908 186.493 39.2591 183.544 38.7365C180.596 38.2139 177.814 39.9982 177.331 42.7219C176.849 45.4455 178.848 48.0772 181.796 48.5998Z" fill="#C7CFE2" />
                  <path id="Vector_69" d="M222.726 55.8541C224.692 56.2025 226.547 55.013 226.868 53.1973C227.19 51.3816 225.858 49.6272 223.892 49.2788C221.926 48.9304 220.072 50.1199 219.75 51.9356C219.428 53.7514 220.761 55.5057 222.726 55.8541Z" fill="#FF6464" />
                  <path id="Vector_70" d="M212.372 62.4963C214.338 62.8447 216.192 61.6552 216.514 59.8395C216.836 58.0238 215.503 56.2694 213.537 55.921C211.572 55.5726 209.717 56.7621 209.396 58.5778C209.074 60.3936 210.406 62.1479 212.372 62.4963Z" fill="#82B9FF" />
                  <path id="Vector_71" d="M215.286 46.058C217.251 46.4064 219.106 45.2169 219.427 43.4011C219.749 41.5854 218.417 39.831 216.451 39.4826C214.485 39.1342 212.631 40.3238 212.309 42.1395C211.987 43.9552 213.32 45.7096 215.286 46.058Z" fill="#00DCC8" />
                  <g id="Group_28">
                    <path id="Vector_72" d="M175.292 54.2289L171.733 53.598C170.75 53.4239 170.084 52.5465 170.245 51.6387C170.405 50.7309 171.333 50.136 172.316 50.3102L175.875 50.941C176.857 51.1152 177.524 51.9926 177.363 52.9004C177.202 53.8082 176.275 54.4031 175.292 54.2289Z" fill="#8B93A6" />
                    <path id="Vector_73" d="M191.126 55.8554L185.268 56.1574C184.683 56.1874 184.209 55.6988 184.304 55.164L184.659 53.1598C184.754 52.6251 185.367 52.329 185.906 52.5583L191.303 54.8552C191.843 55.0854 191.712 55.8252 191.126 55.8554Z" fill="#8B93A6" />
                  </g>
                </g>
                <g id="svg-drawing" className="about-svg">
                  <g id="notebook-svgrepo-com(1) 1" clipPath="url(#clip3_47_2)">
                    <g id="Group_29">
                      <path id="Vector_74" d="M426.672 46.2567L423.233 45.5239L422.5 48.9636L425.94 49.6963L426.672 46.2567Z" fill="#78AAC3" />
                      <path id="Vector_75" d="M442.857 147.942L462.364 56.3743L392.811 41.5575L373.305 133.125L442.857 147.942Z" fill="white" />
                      <path id="Vector_76" d="M462.789 53.9506L457.629 52.8514L456.897 56.2911L460.336 57.0238C461.286 57.2261 461.892 58.1603 461.69 59.1101L460.957 62.5497L444.104 141.662L443.371 145.102C443.169 146.051 442.234 146.657 441.285 146.455L375.931 132.533C374.981 132.33 374.375 131.396 374.578 130.447L375.31 127.007L392.164 47.8947L392.896 44.455C393.099 43.5053 394.033 42.8992 394.983 43.1015L398.422 43.8343L399.155 40.3946L393.996 39.2955C392.096 38.8908 390.228 40.1026 389.823 42.0024L370.772 131.433C370.367 133.333 371.579 135.201 373.478 135.606L442.272 150.261C444.171 150.666 446.039 149.454 446.444 147.554L465.496 58.1229C465.9 56.2232 464.689 54.3553 462.789 53.9506Z" fill="#78AAC3" />
                      <path id="Vector_77" d="M406.034 41.8601L402.595 41.1273L401.862 44.5669L405.302 45.2997L406.034 41.8601Z" fill="#78AAC3" />
                      <path id="Vector_78" d="M412.914 43.3256L409.474 42.5929L408.741 46.0325L412.181 46.7653L412.914 43.3256Z" fill="#78AAC3" />
                      <path id="Vector_79" d="M454.19 52.1187L450.75 51.386L450.017 54.8256L453.457 55.5584L454.19 52.1187Z" fill="#78AAC3" />
                      <path id="Vector_80" d="M419.793 44.7911L416.354 44.0583L415.621 47.498L419.06 48.2307L419.793 44.7911Z" fill="#78AAC3" />
                      <path id="Vector_81" d="M433.552 47.7221L430.112 46.9894L429.379 50.429L432.819 51.1618L433.552 47.7221Z" fill="#78AAC3" />
                      <path id="Vector_82" d="M447.31 50.6532L443.871 49.9204L443.138 53.36L446.577 54.0928L447.31 50.6532Z" fill="#78AAC3" />
                      <path id="Vector_83" d="M440.431 49.1877L436.991 48.455L436.259 51.8946L439.698 52.6273L440.431 49.1877Z" fill="#78AAC3" />
                    </g>
                    <g id="Group_30">
                      <path id="Vector_84" d="M425.573 51.4162C425.371 52.366 425.977 53.3002 426.927 53.5025C427.876 53.7048 428.811 53.0987 429.013 52.149L429.379 50.4291L430.112 46.9894L430.478 45.2697C430.681 44.3199 430.075 43.3857 429.125 43.1834C428.175 42.9811 427.241 43.5872 427.039 44.5369L426.672 46.2568L425.94 49.6963L425.573 51.4162Z" fill="#788CA5" />
                      <path id="Vector_85" d="M432.453 52.8816C432.25 53.8314 432.856 54.7656 433.806 54.9679C434.756 55.1703 435.69 54.5642 435.892 53.6144L436.259 51.8945L436.992 48.4549L437.358 46.7352C437.56 45.7854 436.954 44.8512 436.004 44.6489C435.055 44.4465 434.12 45.0526 433.918 46.0024L433.552 47.7223L432.819 51.1617L432.453 52.8816Z" fill="#788CA5" />
                      <path id="Vector_86" d="M439.332 54.3471C439.129 55.2969 439.735 56.2311 440.685 56.4334C441.635 56.6357 442.569 56.0296 442.772 55.0799L443.138 53.36L443.871 49.9203L444.237 48.2006C444.439 47.2509 443.833 46.3166 442.883 46.1143C441.934 45.912 441 46.5181 440.797 47.4678L440.431 49.1877L439.698 52.6272L439.332 54.3471Z" fill="#788CA5" />
                      <path id="Vector_87" d="M446.211 55.8127C446.009 56.7624 446.615 57.6966 447.565 57.899C448.514 58.1013 449.449 57.4952 449.651 56.5455L450.017 54.8255L450.75 51.3859L451.116 49.6662C451.319 48.7164 450.713 47.7822 449.763 47.5799C448.813 47.3776 447.879 47.9836 447.677 48.9334L447.31 50.6533L446.577 54.0928L446.211 55.8127Z" fill="#788CA5" />
                      <path id="Vector_88" d="M453.09 57.2781C452.888 58.2279 453.494 59.1621 454.444 59.3644C455.394 59.5668 456.328 58.9607 456.53 58.0109L456.897 56.291L457.629 52.8514L457.996 51.1317C458.198 50.1819 457.592 49.2477 456.642 49.0453C455.693 48.843 454.758 49.4491 454.556 50.3989L454.19 52.1188L453.457 55.5582L453.09 57.2781Z" fill="#788CA5" />
                      <path id="Vector_89" d="M398.056 45.5541C397.853 46.5039 398.46 47.4381 399.409 47.6404C400.359 47.8428 401.293 47.2367 401.496 46.2869L401.862 44.567L402.595 41.1274L402.961 39.4077C403.163 38.4579 402.557 37.5237 401.608 37.3213C400.658 37.119 399.724 37.7251 399.521 38.6749L399.155 40.3948L398.422 43.8342L398.056 45.5541Z" fill="#788CA5" />
                      <path id="Vector_90" d="M404.935 47.0196C404.733 47.9693 405.339 48.9036 406.288 49.1059C407.238 49.3082 408.172 48.7021 408.375 47.7524L408.741 46.0325L409.474 42.5928L409.84 40.8731C410.043 39.9233 409.437 38.9891 408.487 38.7868C407.537 38.5845 406.603 39.1906 406.4 40.1403L406.034 41.8602L405.301 45.2997L404.935 47.0196Z" fill="#788CA5" />
                      <path id="Vector_91" d="M411.815 48.4852C411.612 49.4349 412.218 50.3691 413.168 50.5715C414.118 50.7738 415.052 50.1677 415.254 49.2179L415.621 47.498L416.354 44.0584L416.72 42.3387C416.922 41.3889 416.316 40.4547 415.366 40.2524C414.417 40.0501 413.482 40.6561 413.28 41.6059L412.914 43.3258L412.181 46.7652L411.815 48.4852Z" fill="#788CA5" />
                      <path id="Vector_92" d="M418.694 49.9506C418.491 50.9004 419.097 51.8346 420.047 52.0369C420.997 52.2392 421.931 51.6332 422.134 50.6834L422.5 48.9635L423.233 45.5239L423.599 43.8041C423.801 42.8544 423.195 41.9202 422.246 41.7178C421.296 41.5155 420.362 42.1216 420.159 43.0714L419.793 44.7913L419.06 48.2307L418.694 49.9506Z" fill="#788CA5" />
                    </g>
                  </g>
                  <g id="draw-svgrepo-com 1" clipPath="url(#clip4_47_2)">
                    <path id="Vector_93" d="M404.659 61.4768C398.895 69.4026 421.231 62.4855 422.672 64.3589C424.113 66.5205 408.261 70.8436 408.261 75.8873C408.261 80.9309 444.287 67.9615 443.567 73.0052C442.846 77.3283 405.884 86.6951 407.541 91.0182C408.982 94.6209 422.672 92.4593 423.392 95.3414C424.113 98.2235 403.938 105.429 403.218 116.237C402.497 127.044 411.864 129.926 418.349 122.721" stroke="#8B93A6" strokeWidth="6" />
                    <path id="Vector_94" d="M404.659 61.4768C398.895 69.4026 421.231 62.4855 422.672 64.3589C424.113 66.5205 408.261 70.8436 408.261 75.8873C408.261 80.9309 444.287 67.9615 443.567 73.0052C442.846 77.3283 405.884 86.6951 407.541 91.0183C408.982 94.6209 422.672 92.4593 423.392 95.3414C424.113 98.2235 403.938 105.429 403.218 116.237C402.497 127.044 411.864 129.926 418.349 122.721" stroke="#8B93A6" strokeWidth="4" />
                  </g>
                  <g id="Pencil">
                    <path id="Vector_95" d="M427.481 106.58L433.238 113.888L464.294 89.4169L461.415 85.7634L458.536 82.1098L449.063 89.5742L443.918 93.6283L427.481 106.58Z" fill="#FFD155" />
                    <path id="Vector_96" d="M464.294 89.4168L464.294 89.417L433.239 113.888L438.997 121.195L470.048 96.727L470.052 96.7239L464.294 89.4168Z" fill="#FFE49C" />
                    <g id="Group_31">
                      <path id="Vector_97" d="M458.536 82.1099L461.415 85.7634L458.536 82.1099Z" fill="#FFF0BE" />
                      <path id="Vector_98" d="M470.052 96.724L474.934 92.8773C475.017 92.8116 475.096 92.7425 475.171 92.6703C476.297 91.5826 476.438 89.797 475.448 88.5408L471.601 83.6591L464.294 89.4171L470.052 96.724Z" fill="#FFF0BE" />
                      <path id="Vector_99" d="M416.583 125.92C416.605 126.586 417.1 127.177 417.764 127.299C417.1 127.177 416.605 126.586 416.583 125.92Z" fill="#FFF0BE" />
                      <path id="Vector_100" d="M426.984 124.738L425.591 125.149L438.997 121.195L433.239 113.888L424.105 121.085L426.984 124.738Z" fill="#FFF0BE" />
                    </g>
                    <path id="Vector_101" d="M419.301 120.77L421.226 117.431L424.105 121.085L433.239 113.888L427.481 106.58L419.301 120.77L419.301 120.77Z" fill="#FFDC9B" />
                    <path id="Vector_102" d="M424.105 121.085L421.226 117.431L419.301 120.77L416.776 125.151C416.63 125.404 416.574 125.667 416.583 125.92C416.605 126.586 417.1 127.178 417.764 127.299C417.975 127.338 418.203 127.329 418.438 127.259L425.591 125.149L426.984 124.738L424.105 121.085Z" fill="#606367" />
                    <path id="Vector_103" d="M464.294 89.4168L471.601 83.659L467.653 78.6483C466.653 77.38 464.815 77.162 463.547 78.1613L458.536 82.1097L461.415 85.7633L464.294 89.4168Z" fill="#FFDC9B" />
                  </g>
                </g>
                <g id="svg-boots" className="about-svg">
                  <g id="boots-svgrepo-com(1) 1" clipPath="url(#clip5_47_2)">
                    <path id="Vector_104" d="M273.881 449.945L309.326 436.807C310.55 436.354 311.182 435.016 310.739 433.82L307.527 425.155C307.084 423.96 305.733 423.357 304.509 423.81L269.064 436.948C267.84 437.402 267.208 438.74 267.651 439.935L270.863 448.6C271.306 449.796 272.657 450.399 273.881 449.945Z" fill="#FFDAAA" />
                    <path id="Vector_105" d="M269.064 436.948L286.786 430.379L291.604 443.376L273.881 449.945C272.657 450.399 271.306 449.796 270.863 448.6L267.651 439.935C267.208 438.74 267.84 437.402 269.064 436.948Z" fill="#FFB655" />
                    <path id="Vector_106" d="M245.82 460.346L265.019 453.229C266.243 452.776 266.875 451.438 266.432 450.242L263.22 441.578C262.777 440.382 261.426 439.779 260.202 440.233L241.002 447.349C239.779 447.802 239.147 449.14 239.59 450.336L242.801 459.001C243.245 460.197 244.596 460.799 245.82 460.346Z" fill="#FFDAAA" />
                    <path id="Vector_107" d="M241.002 447.349L250.602 443.791L255.419 456.787L245.82 460.346C244.596 460.799 243.245 460.196 242.801 459.001L239.59 450.336C239.147 449.14 239.779 447.802 241.002 447.349Z" fill="#FFB655" />
                    <path id="Vector_108" d="M240.393 452.502L308.232 427.358C304.284 412.775 288.495 405.109 274.327 410.36L267.681 412.824L256.708 383.22C256.265 382.024 254.914 381.422 253.69 381.875L221.198 393.918C219.975 394.371 219.343 395.709 219.786 396.905L240.393 452.502Z" fill="#AA6100" />
                    <path id="Vector_109" d="M221.199 393.918L237.445 387.897L258.854 445.66L240.393 452.502L219.786 396.905C219.343 395.709 219.975 394.372 221.199 393.918Z" fill="#884E00" />
                    <path id="Vector_110" d="M222.195 403.404L259.117 389.719L260.723 394.051L223.8 407.736L222.195 403.404Z" fill="#FFDAAA" />
                    <path id="Vector_111" d="M222.195 403.404L240.656 396.561L242.262 400.893L223.801 407.736L222.195 403.404Z" fill="#FFB655" />
                  </g>
                  <g id="boots-svgrepo-com(1) 2" clipPath="url(#clip6_47_2)">
                    <path id="Vector_112" d="M251.028 477.372L288.263 470.85C289.549 470.625 290.412 469.423 290.192 468.167L288.597 459.065C288.377 457.808 287.157 456.972 285.871 457.197L248.636 463.719C247.351 463.944 246.488 465.146 246.708 466.402L248.302 475.504C248.522 476.76 249.742 477.597 251.028 477.372Z" fill="#FFDAAA" />
                    <path id="Vector_113" d="M248.636 463.719L267.254 460.458L269.645 474.111L251.028 477.372C249.742 477.597 248.522 476.76 248.302 475.504L246.708 466.402C246.488 465.146 247.351 463.944 248.636 463.719Z" fill="#FFB655" />
                    <path id="Vector_114" d="M221.55 482.535L241.719 479.002C243.005 478.777 243.868 477.575 243.648 476.319L242.053 467.217C241.833 465.961 240.613 465.124 239.328 465.349L219.158 468.882C217.873 469.107 217.01 470.309 217.23 471.565L218.824 480.667C219.044 481.924 220.265 482.76 221.55 482.535Z" fill="#FFDAAA" />
                    <path id="Vector_115" d="M219.159 468.882L229.243 467.116L231.635 480.769L221.55 482.535C220.264 482.76 219.044 481.923 218.824 480.667L217.23 471.565C217.01 470.309 217.873 469.107 219.159 468.882Z" fill="#FFB655" />
                    <path id="Vector_116" d="M217.628 473.841L288.892 461.358C287.642 446.302 273.497 435.911 258.613 438.518L251.632 439.741L246.185 408.643C245.965 407.387 244.744 406.55 243.459 406.775L209.327 412.754C208.042 412.979 207.178 414.18 207.398 415.437L217.628 473.841Z" fill="#AA6100" />
                    <path id="Vector_117" d="M209.327 412.754L226.393 409.765L237.022 470.444L217.628 473.841L207.398 415.437C207.178 414.18 208.042 412.979 209.327 412.754Z" fill="#884E00" />
                    <path id="Vector_118" d="M208.594 422.263L247.381 415.47L248.178 420.021L209.391 426.814L208.594 422.263Z" fill="#FFDAAA" />
                    <path id="Vector_119" d="M208.594 422.263L227.988 418.866L228.785 423.417L209.391 426.814L208.594 422.263Z" fill="#FFB655" />
                  </g>
                </g>
                <g id="svg-laptop-3" className="about-svg" clipPath="url(#clip7_47_2)">
                  <path id="Vector_120" d="M107.613 184.397L15.2108 208.387C12.3476 209.131 10.6133 212.081 11.3567 214.945L28.2516 280.017L81.01 271.876L131.065 253.323L114.171 188.251C113.427 185.388 110.476 183.653 107.613 184.397Z" fill="#41BBBE" />
                  <path id="Vector_121" d="M131.065 253.323L28.2515 280.017L24.4976 297.662L142.929 266.913L131.065 253.323Z" fill="#A0DDDF" />
                  <path id="Vector_122" d="M107.037 192.881L19.8408 215.521L34.0325 270.181L121.229 247.542L107.037 192.881Z" fill="#EEA0B6" />
                  <path id="Vector_123" d="M90.0012 274.403L74.384 278.458C73.3056 278.738 72.2048 278.091 71.9248 277.013C71.6449 275.934 72.2919 274.834 73.3702 274.554L88.9874 270.499C90.0658 270.219 91.1666 270.866 91.4466 271.944C91.7265 273.023 91.0795 274.123 90.0012 274.403Z" fill="#20AAB0" />
                  <path id="Vector_124" d="M24.4976 297.662L26.5249 305.47C27.64 309.765 32.0661 312.366 36.3609 311.251L139.175 284.557C143.47 283.442 146.071 279.016 144.956 274.722L142.929 266.913L24.4976 297.662Z" fill="#41BBBE" />
                  <path id="Vector_125" d="M86.2475 292.048C87.3257 291.768 87.9728 290.667 87.6929 289.589C87.413 288.511 86.312 287.864 85.2338 288.144C84.1556 288.424 83.5084 289.525 83.7884 290.603C84.0683 291.681 85.1693 292.328 86.2475 292.048Z" fill="#086063" />
                  <g id="Group_32">
                    <path id="Vector_126" d="M52.7325 249.619L38.9608 241.524C37.5583 240.7 37.0896 238.895 37.914 237.492L46.0084 223.721C47.9806 220.365 53.0616 223.347 51.0873 226.706L44.4853 237.938L55.7176 244.54C59.0727 246.512 56.0793 251.586 52.7325 249.619Z" fill="white" />
                    <path id="Vector_127" d="M61.7748 247.127L70.7558 214.623C71.7918 210.872 77.4723 212.435 76.4343 216.192L67.4534 248.696C66.4169 252.448 60.7371 250.883 61.7748 247.127Z" fill="white" />
                  </g>
                  <path id="Vector_128" d="M85.694 236.787L92.3945 225.491L81.0449 218.881C77.6548 216.907 80.6795 211.796 84.0745 213.773L97.9898 221.877C99.406 222.702 99.8775 224.515 99.0409 225.926L90.8258 239.776C88.8325 243.136 83.6702 240.199 85.694 236.787Z" fill="white" />
                </g>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_47_2">
                <rect width="237" height="236" fill="white" transform="translate(150 107)" />
              </clipPath>
              <clipPath id="clip1_47_2">
                <rect width="120" height="120" fill="white" transform="translate(398 259.558)" />
              </clipPath>
              <clipPath id="clip2_47_2">
                <rect width="104.825" height="96.8297" fill="white" transform="translate(137.181) rotate(10.0508)" />
              </clipPath>
              <clipPath id="clip3_47_2">
                <rect width="101.988" height="101.988" fill="white" transform="translate(379.25 32.5585) rotate(12.026)" />
              </clipPath>
              <clipPath id="clip4_47_2">
                <rect width="72.0523" height="72.0523" fill="white" transform="translate(398.895 57.8741)" />
              </clipPath>
              <clipPath id="clip5_47_2">
                <rect width="72.4538" height="70.8437" fill="white" transform="matrix(-0.937665 0.347541 0.347541 0.937665 286.921 369.558)" />
              </clipPath>
              <clipPath id="clip6_47_2">
                <rect width="72.4538" height="70.8437" fill="white" transform="matrix(-0.985004 0.172534 0.172534 0.985004 278.367 400.661)" />
              </clipPath>
              <clipPath id="clip7_47_2">
                <rect width="122.358" height="122.358" fill="white" transform="translate(0 203.307) rotate(-14.5545)" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className="col-12 col-xl-7 about-me p-5 d-flex flex-column justify-content-evenly">
          <p className="px-3">I am a <span className="fw-bold wave-word">
            <span style={{ "--letter":1 }} >f</span>
            <span style={{ "--letter":2 }}>u</span>
            <span style={{ "--letter":3 }}>l</span>
            <span style={{ "--letter":4 }}>l</span>&nbsp;
            <span style={{ "--letter":5 }}>s</span>
            <span style={{ "--letter":6 }}>t</span>
            <span style={{ "--letter":7 }}>a</span>
            <span style={{ "--letter":8 }}>c</span>
            <span style={{ "--letter":9 }}>k</span> web developer
            </span>
              , with a range of talent across multiple disciplines.</p>
          <p className="px-3">I have skill and experience in: <br /><b>html css javascript vue react laravel and php python aws(S3) ruby on rails bootstrap sass git figma </b> and app developement with <b>kotlin</b> and <b>dart in flutter</b>.</p>
          <p className="px-3">Aside from coding, I enjoy gaming, drawing, playing ukulele and adventuring with my pupp.</p>
        </div>
      </div>
    </div>
  )
}

export default About;