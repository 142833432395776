import React from 'react';
import projectImage1 from '../images/client-project.png';
import projectImage2 from '../images/twitter-project.png';
import projectImage3 from '../images/accurrency-project.png';
import projectImage4 from '../images/todo-list-project.png';

function Projects() {
  return (
    <div id="projectPage" className="container my-5 pt-5">
      <h3 className="text-center pb-2 pb-xl-2">projects</h3>
      <div className="row gx-0 ms-xl-4">

        <div className="col-12 col-xxl-3 p-3 p-xxl-0 d-flex justify-content-center">
          <svg className="project-svgs" width="315" height="330" viewBox="0 0 461 483" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="projects-svgs">
              <g id="svg-laptop-2" clipPath="url(#clip0_47_3)">
                <path id="Vector" d="M181.414 263.232L105.746 252.417L122.292 136.663C122.825 132.931 126.283 130.339 130.014 130.872L311.077 156.753C314.808 157.286 317.4 160.743 316.867 164.474L300.322 280.229L224.654 269.413L181.414 263.232ZM302.388 188.602L305.478 166.983L132.522 142.261L129.432 163.881L118.101 243.152L291.057 267.874L302.388 188.602Z" fill="#464655" />
                <path id="Vector_2" d="M129.432 163.881L302.388 188.602L291.057 267.874L118.101 243.152L129.432 163.881ZM213.335 194.258L206.639 241.1C206.355 243.09 207.738 244.934 209.728 245.218L278.189 255.004C280.179 255.288 282.023 253.906 282.308 251.916L289.003 205.073L289.518 201.47C289.803 199.48 288.42 197.636 286.43 197.352L217.968 187.566C215.978 187.281 214.134 188.664 213.85 190.654L213.335 194.258ZM177.302 189.107L170.607 235.95C170.322 237.939 171.705 239.784 173.695 240.068L195.315 243.158C197.305 243.443 199.149 242.06 199.433 240.07L206.129 193.228L206.644 189.624C206.928 187.634 205.545 185.79 203.555 185.506L181.936 182.416C179.946 182.131 178.102 183.514 177.817 185.504L177.302 189.107ZM137.667 183.442L130.971 230.284C130.687 232.274 132.07 234.118 134.06 234.403L155.679 237.493C157.669 237.777 159.513 236.394 159.798 234.405L166.493 187.562L167.008 183.959C167.293 181.969 165.91 180.125 163.92 179.84L142.3 176.75C140.31 176.466 138.466 177.849 138.182 179.839L137.667 183.442Z" fill="white" />
                <path id="Vector_3" d="M132.522 142.261L305.478 166.983L302.388 188.603L129.432 163.881L132.522 142.261ZM256.576 174.7L292.608 179.851C294.598 180.135 296.442 178.752 296.727 176.762C297.011 174.772 295.628 172.928 293.638 172.644L257.606 167.494C255.616 167.209 253.772 168.592 253.487 170.582C253.203 172.572 254.586 174.416 256.576 174.7ZM227.75 170.58L242.163 172.64C244.153 172.925 245.997 171.542 246.281 169.552C246.566 167.562 245.183 165.718 243.193 165.433L228.78 163.373C226.79 163.089 224.946 164.472 224.662 166.462C224.377 168.452 225.76 170.296 227.75 170.58ZM198.924 166.46L213.337 168.52C215.327 168.804 217.171 167.421 217.455 165.432C217.74 163.442 216.357 161.598 214.367 161.313L199.954 159.253C197.964 158.969 196.12 160.352 195.836 162.341C195.551 164.331 196.934 166.175 198.924 166.46ZM170.098 162.34L184.511 164.4C186.501 164.684 188.345 163.301 188.629 161.311C188.914 159.321 187.531 157.477 185.541 157.193L171.128 155.133C169.138 154.848 167.294 156.231 167.009 158.221C166.725 160.211 168.108 162.055 170.098 162.34ZM141.272 158.219L155.685 160.279C157.675 160.564 159.519 159.181 159.803 157.191C160.088 155.201 158.705 153.357 156.715 153.073L142.302 151.013C140.312 150.728 138.468 152.111 138.184 154.101C137.899 156.091 139.282 157.935 141.272 158.219Z" fill="#FF6469" />
                <path id="Vector_4" d="M257.606 167.494L293.638 172.644C295.628 172.929 297.011 174.773 296.726 176.763C296.442 178.753 294.598 180.136 292.608 179.851L256.575 174.701C254.586 174.416 253.203 172.572 253.487 170.582C253.772 168.592 255.616 167.21 257.606 167.494Z" fill="#FFDC69" />
                <g id="Group">
                  <path id="Vector_5" d="M228.78 163.374L243.193 165.434C245.183 165.718 246.566 167.562 246.281 169.552C245.997 171.542 244.152 172.925 242.163 172.641L227.75 170.581C225.76 170.296 224.377 168.452 224.661 166.462C224.946 164.472 226.79 163.089 228.78 163.374Z" fill="#D2555F" />
                  <path id="Vector_6" d="M199.954 159.253L214.367 161.314C216.357 161.598 217.74 163.442 217.455 165.432C217.171 167.422 215.327 168.805 213.337 168.52L198.924 166.46C196.934 166.176 195.551 164.332 195.836 162.342C196.12 160.352 197.964 158.969 199.954 159.253Z" fill="#D2555F" />
                  <path id="Vector_7" d="M171.128 155.133L185.541 157.193C187.531 157.478 188.913 159.322 188.629 161.312C188.345 163.302 186.5 164.685 184.511 164.4L170.098 162.34C168.108 162.056 166.725 160.211 167.009 158.221C167.294 156.232 169.138 154.849 171.128 155.133Z" fill="#D2555F" />
                  <path id="Vector_8" d="M142.302 151.013L156.715 153.073C158.705 153.357 160.088 155.202 159.803 157.191C159.519 159.181 157.675 160.564 155.685 160.28L141.272 158.22C139.282 157.935 137.899 156.091 138.184 154.101C138.468 152.111 140.312 150.728 142.302 151.013Z" fill="#D2555F" />
                </g>
                <g id="Group_2">
                  <path id="Vector_9" d="M216.423 198.376L284.885 208.162C286.875 208.446 288.719 207.063 289.003 205.073L282.308 251.916C282.023 253.906 280.179 255.288 278.189 255.004L209.728 245.218C207.738 244.934 206.355 243.09 206.639 241.1L213.335 194.258C213.05 196.248 214.433 198.092 216.423 198.376Z" fill="#DEE3ED" />
                  <path id="Vector_10" d="M180.391 193.226L202.01 196.316C204 196.6 205.844 195.218 206.129 193.228L199.433 240.07C199.149 242.06 197.305 243.443 195.315 243.158L173.695 240.068C171.705 239.784 170.322 237.939 170.607 235.95L177.302 189.107C177.018 191.097 178.401 192.941 180.391 193.226Z" fill="#DEE3ED" />
                  <path id="Vector_11" d="M140.755 187.56L162.374 190.651C164.364 190.935 166.208 189.552 166.493 187.562L159.797 234.405C159.513 236.394 157.669 237.777 155.679 237.493L134.059 234.403C132.069 234.118 130.686 232.274 130.971 230.284L137.666 183.442C137.382 185.432 138.765 187.276 140.755 187.56Z" fill="#DEE3ED" />
                </g>
                <path id="Vector_12" d="M217.968 187.566L286.43 197.352C288.42 197.636 289.803 199.481 289.518 201.47L289.003 205.074C288.719 207.064 286.874 208.447 284.885 208.162L216.423 198.377C214.433 198.092 213.05 196.248 213.335 194.258L213.85 190.655C214.134 188.664 215.978 187.282 217.968 187.566Z" fill="#FFDC69" />
                <path id="Vector_13" d="M181.936 182.416L203.555 185.506C205.545 185.791 206.928 187.635 206.644 189.625L206.129 193.228C205.844 195.218 204 196.601 202.01 196.316L180.39 193.226C178.401 192.942 177.018 191.098 177.302 189.108L177.817 185.504C178.102 183.514 179.946 182.132 181.936 182.416Z" fill="#B4E173" />
                <path id="Vector_14" d="M142.3 176.751L163.919 179.841C165.909 180.125 167.292 181.969 167.008 183.959L166.493 187.563C166.208 189.553 164.364 190.935 162.374 190.651L140.754 187.561C138.765 187.276 137.382 185.432 137.666 183.442L138.181 179.839C138.466 177.849 140.31 176.466 142.3 176.751Z" fill="#FF969B" />
                <path id="laptop-bottom" d="M300.319 280.228L105.748 252.416L144.013 332.249C145.89 336.165 149.598 338.885 153.896 339.5L326.774 364.21C333.103 365.115 337.884 358.598 335.12 352.833L300.319 280.228Z" fill="#464655" />
                <g id="keyboard-back">
                  <path d="M290.915 289.485C289.747 287.117 287.486 285.477 284.872 285.104L129.622 262.913C127.233 262.571 125.439 265.046 126.506 267.209L144.056 302.804C145.224 305.173 147.486 306.812 150.1 307.186L305.35 329.377C307.738 329.718 309.532 327.244 308.465 325.08L290.915 289.485Z" fill="#BEC8DC" />
                  <path d="M290.915 289.485C289.747 287.117 287.486 285.477 284.872 285.104L129.622 262.913C127.233 262.571 125.439 265.046 126.506 267.209L144.056 302.804C145.224 305.173 147.486 306.812 150.1 307.186L305.35 329.377C307.738 329.718 309.532 327.244 308.465 325.08L290.915 289.485Z" fill="#BEC8DC" />
                </g>
                <g id="keys">
                  <g id="keyboard-keys">
                    <path id="Vector_15" d="M138.309 268.507L159.634 271.555C160.812 271.723 161.576 273.197 161.34 274.845C161.105 276.494 159.959 277.694 158.781 277.526L137.456 274.478C136.278 274.31 135.514 272.836 135.75 271.188C135.986 269.539 137.132 268.339 138.309 268.507Z" fill="#96A5B9" />
                    <path id="Vector_16" d="M202.679 303.821L247.462 310.222C249.935 310.575 251.75 312.199 251.514 313.848C251.278 315.496 249.082 316.547 246.609 316.193L201.826 309.792C199.353 309.438 197.538 307.815 197.774 306.166C198.01 304.518 200.206 303.467 202.679 303.821Z" fill="#96A5B9" />
                    <g id="keyboard-keys_2">
                      <path id="Vector_17" d="M270.384 287.385L283.122 289.206C284.881 289.457 286.116 290.998 285.88 292.647C285.644 294.295 284.027 295.428 282.269 295.177L269.531 293.356C267.772 293.105 266.537 291.564 266.773 289.915C267.008 288.267 268.625 287.134 270.384 287.385Z" fill="#96A5B9" />
                      <path id="Vector_18" d="M244.907 283.744L257.646 285.564C259.404 285.816 260.639 287.356 260.404 289.005C260.168 290.654 258.551 291.787 256.792 291.535L244.054 289.715C242.295 289.463 241.06 287.923 241.296 286.274C241.532 284.625 243.149 283.492 244.907 283.744Z" fill="#A3AEC1" />
                      <path id="Vector_19" d="M219.431 280.102L232.169 281.923C233.927 282.174 235.162 283.715 234.927 285.364C234.691 287.012 233.074 288.145 231.315 287.894L218.577 286.073C216.818 285.822 215.583 284.281 215.819 282.632C216.055 280.984 217.672 279.851 219.431 280.102Z" fill="#A3AEC1" />
                      <path id="Vector_20" d="M193.954 276.46L206.692 278.281C208.451 278.533 209.686 280.073 209.45 281.722C209.215 283.371 207.598 284.504 205.839 284.252L193.101 282.432C191.342 282.18 190.107 280.64 190.343 278.991C190.578 277.342 192.196 276.209 193.954 276.46Z" fill="#A3AEC1" />
                      <path id="Vector_21" d="M168.875 272.876L181.614 274.697C183.372 274.948 184.607 276.489 184.372 278.137C184.136 279.786 182.519 280.919 180.76 280.668L168.022 278.847C166.263 278.596 165.028 277.055 165.264 275.406C165.5 273.757 167.117 272.624 168.875 272.876Z" fill="#A3AEC1" />
                      <path id="Vector_22" d="M274.283 296.647L287.021 298.468C288.78 298.719 290.015 300.26 289.779 301.908C289.543 303.557 287.926 304.69 286.168 304.439L273.429 302.618C271.671 302.366 270.436 300.826 270.671 299.177C270.907 297.528 272.524 296.395 274.283 296.647Z" fill="#96A5B9" />
                      <path id="Vector_23" d="M258.076 294.33L266.037 295.468C267.136 295.625 267.836 297.089 267.601 298.738C267.365 300.387 266.283 301.596 265.183 301.439L257.222 300.301C256.123 300.144 255.423 298.68 255.658 297.031C255.894 295.383 256.976 294.173 258.076 294.33Z" fill="#A3AEC1" />
                      <path id="Vector_24" d="M288.052 316.024L296.013 317.162C297.112 317.319 297.812 318.783 297.577 320.432C297.341 322.08 296.259 323.29 295.159 323.133L287.198 321.995C286.099 321.838 285.399 320.373 285.634 318.725C285.87 317.076 286.953 315.867 288.052 316.024Z" fill="#96A5B9" />
                      <path id="Vector_25" d="M272.697 313.829L280.659 314.967C281.758 315.124 282.458 316.588 282.222 318.237C281.987 319.886 280.904 321.095 279.805 320.938L271.844 319.8C270.745 319.643 270.045 318.179 270.28 316.53C270.516 314.881 271.598 313.672 272.697 313.829Z" fill="#A3AEC1" />
                      <path id="Vector_26" d="M258.196 311.756L266.158 312.894C267.257 313.051 267.957 314.516 267.721 316.164C267.486 317.813 266.403 319.022 265.304 318.865L257.343 317.727C256.244 317.57 255.543 316.106 255.779 314.457C256.015 312.809 257.097 311.599 258.196 311.756Z" fill="#A3AEC1" />
                      <path id="Vector_27" d="M182.278 300.905L190.239 302.043C191.338 302.2 192.039 303.664 191.803 305.313C191.567 306.961 190.485 308.171 189.386 308.014L181.425 306.876C180.325 306.719 179.625 305.254 179.861 303.606C180.096 301.957 181.179 300.748 182.278 300.905Z" fill="#A3AEC1" />
                      <path id="Vector_28" d="M166.924 298.71L174.885 299.848C175.984 300.005 176.684 301.469 176.449 303.118C176.213 304.767 175.131 305.976 174.032 305.819L166.07 304.681C164.971 304.524 164.271 303.06 164.507 301.411C164.742 299.762 165.825 298.553 166.924 298.71Z" fill="#A3AEC1" />
                      <path id="Vector_29" d="M152.423 296.637L160.384 297.775C161.483 297.932 162.183 299.397 161.948 301.045C161.712 302.694 160.63 303.904 159.53 303.746L151.569 302.608C150.47 302.451 149.77 300.987 150.005 299.338C150.241 297.69 151.323 296.48 152.423 296.637Z" fill="#96A5B9" />
                      <path id="Vector_30" d="M242.721 292.136L250.683 293.273C251.782 293.431 252.482 294.895 252.246 296.544C252.011 298.192 250.928 299.402 249.829 299.245L241.868 298.107C240.769 297.95 240.069 296.485 240.304 294.837C240.54 293.188 241.622 291.978 242.721 292.136Z" fill="#A3AEC1" />
                      <path id="Vector_31" d="M227.367 289.941L235.328 291.079C236.428 291.236 237.128 292.7 236.892 294.349C236.656 295.998 235.574 297.207 234.475 297.05L226.514 295.912C225.414 295.755 224.714 294.291 224.95 292.642C225.186 290.993 226.268 289.784 227.367 289.941Z" fill="#A3AEC1" />
                      <path id="Vector_32" d="M212.866 287.868L220.827 289.006C221.926 289.163 222.627 290.627 222.391 292.276C222.155 293.925 221.073 295.134 219.974 294.977L212.012 293.839C210.913 293.682 210.213 292.218 210.449 290.569C210.684 288.92 211.767 287.711 212.866 287.868Z" fill="#A3AEC1" />
                      <path id="Vector_33" d="M197.512 285.673L205.473 286.811C206.572 286.968 207.272 288.433 207.037 290.081C206.801 291.73 205.719 292.94 204.619 292.782L196.658 291.645C195.559 291.487 194.859 290.023 195.094 288.374C195.33 286.726 196.412 285.516 197.512 285.673Z" fill="#A3AEC1" />
                      <path id="Vector_34" d="M182.158 283.479L190.119 284.617C191.218 284.774 191.918 286.238 191.683 287.887C191.447 289.535 190.365 290.745 189.265 290.588L181.304 289.45C180.205 289.293 179.505 287.828 179.74 286.18C179.976 284.531 181.058 283.322 182.158 283.479Z" fill="#A3AEC1" />
                      <path id="Vector_35" d="M166.803 281.284L174.765 282.422C175.864 282.579 176.564 284.043 176.328 285.692C176.093 287.341 175.01 288.55 173.911 288.393L165.95 287.255C164.851 287.098 164.151 285.634 164.386 283.985C164.622 282.336 165.704 281.127 166.803 281.284Z" fill="#A3AEC1" />
                      <path id="Vector_36" d="M262.828 303.714L270.789 304.852C271.888 305.009 272.588 306.473 272.353 308.122C272.117 309.77 271.035 310.98 269.935 310.823L261.974 309.685C260.875 309.528 260.175 308.063 260.41 306.415C260.646 304.766 261.728 303.557 262.828 303.714Z" fill="#A3AEC1" />
                      <path id="Vector_37" d="M247.473 301.519L255.435 302.657C256.534 302.814 257.234 304.278 256.998 305.927C256.763 307.576 255.68 308.785 254.581 308.628L246.62 307.49C245.521 307.333 244.82 305.869 245.056 304.22C245.292 302.571 246.374 301.362 247.473 301.519Z" fill="#A3AEC1" />
                      <path id="Vector_38" d="M232.119 299.324L240.08 300.462C241.18 300.619 241.88 302.084 241.644 303.732C241.408 305.381 240.326 306.591 239.227 306.433L231.266 305.296C230.166 305.138 229.466 303.674 229.702 302.025C229.938 300.377 231.02 299.167 232.119 299.324Z" fill="#A3AEC1" />
                      <path id="Vector_39" d="M216.765 297.13L224.726 298.268C225.825 298.425 226.525 299.889 226.29 301.538C226.054 303.186 224.972 304.396 223.873 304.239L215.911 303.101C214.812 302.944 214.112 301.48 214.348 299.831C214.583 298.182 215.666 296.973 216.765 297.13Z" fill="#A3AEC1" />
                      <path id="Vector_40" d="M201.411 294.935L209.372 296.073C210.471 296.23 211.171 297.694 210.936 299.343C210.7 300.992 209.618 302.201 208.518 302.044L200.557 300.906C199.458 300.749 198.758 299.285 198.993 297.636C199.229 295.987 200.311 294.778 201.411 294.935Z" fill="#A3AEC1" />
                      <path id="Vector_41" d="M186.056 292.74L194.018 293.878C195.117 294.035 195.817 295.5 195.581 297.148C195.346 298.797 194.263 300.007 193.164 299.849L185.203 298.711C184.104 298.554 183.403 297.09 183.639 295.441C183.875 293.793 184.957 292.583 186.056 292.74Z" fill="#A3AEC1" />
                      <path id="Vector_42" d="M170.702 290.546L178.663 291.684C179.763 291.841 180.463 293.305 180.227 294.954C179.991 296.602 178.909 297.812 177.81 297.655L169.849 296.517C168.749 296.36 168.049 294.895 168.285 293.247C168.521 291.598 169.603 290.389 170.702 290.546Z" fill="#A3AEC1" />
                      <path id="Vector_43" d="M279.035 306.03L291.773 307.851C293.531 308.103 294.766 309.643 294.531 311.292C294.295 312.941 292.678 314.074 290.919 313.822L278.181 312.001C276.422 311.75 275.188 310.209 275.423 308.561C275.659 306.912 277.276 305.779 279.035 306.03Z" fill="#96A5B9" />
                      <path id="Vector_44" d="M143.772 277.992L156.51 279.813C158.269 280.064 159.504 281.605 159.268 283.254C159.032 284.902 157.415 286.035 155.657 285.784L142.918 283.963C141.16 283.712 139.925 282.171 140.16 280.522C140.396 278.874 142.013 277.741 143.772 277.992Z" fill="#96A5B9" />
                      <path id="Vector_45" d="M147.671 287.254L160.409 289.074C162.167 289.326 163.402 290.867 163.167 292.515C162.931 294.164 161.314 295.297 159.555 295.046L146.817 293.225C145.058 292.973 143.823 291.433 144.059 289.784C144.295 288.135 145.912 287.002 147.671 287.254Z" fill="#96A5B9" />
                    </g>
                  </g>
                </g>
                <path id="keypad" d="M261.621 322.914L199.254 314L211.344 338.962L273.71 347.876L261.621 322.914Z" fill="#96A5B9" />
              </g>
              <g id="logo-svgs">
                <g id="svg-html-logo" className="logo-svg">
                  <g id="Group_3">
                    <path id="Vector_46" d="M128.724 108.024L156.656 100.196L162.842 30.7072H94.8018L100.988 100.196L128.724 108.024ZM150.18 44.9153L149.407 53.3725H116.838L117.562 62.118H148.683L146.265 88.4056L128.918 93.1877V93.2365H128.724L111.231 88.4056L110.169 74.9707H118.625L119.253 81.736L128.724 84.2961L138.243 81.736L139.309 70.7196H109.734L107.463 44.9153H150.18Z" fill="#FF5023" />
                  </g>
                </g>
                <g id="svg-figma-logo" className="logo-svg" clipPath="url(#clip1_47_3)">
                  <g id="Group_4">
                    <path id="Vector_47" d="M45.3819 224.612C51.6478 224.612 56.7273 219.532 56.7273 213.266C56.7273 207 51.6478 201.921 45.3819 201.921C39.1159 201.921 34.0364 207 34.0364 213.266C34.0364 219.532 39.1159 224.612 45.3819 224.612Z" fill="#19BCFE" />
                    <path id="Vector_48" fillRule="evenodd" clipRule="evenodd" d="M22.6909 247.303C28.9569 247.303 34.0364 242.223 34.0364 235.957C34.0364 234.247 34.0364 230.465 34.0364 224.612C28.9396 224.612 25.1577 224.612 22.6909 224.612C16.425 224.612 11.3455 229.691 11.3455 235.957C11.3455 242.223 16.425 247.303 22.6909 247.303Z" fill="#09CF83" />
                    <path id="Vector_49" fillRule="evenodd" clipRule="evenodd" d="M22.6909 224.612C24.7796 224.612 28.5614 224.612 34.0364 224.612V201.921C28.5614 201.921 24.7796 201.921 22.6909 201.921C16.425 201.921 11.3455 207 11.3455 213.266C11.3455 219.532 16.425 224.612 22.6909 224.612Z" fill="#A259FF" />
                    <path id="Vector_50" fillRule="evenodd" clipRule="evenodd" d="M22.6909 201.921C24.7796 201.921 28.5614 201.921 34.0364 201.921V179.23C28.5614 179.23 24.7796 179.23 22.6909 179.23C16.425 179.23 11.3455 184.309 11.3455 190.575C11.3455 196.841 16.425 201.921 22.6909 201.921Z" fill="#F24E1E" />
                    <path id="Vector_51" fillRule="evenodd" clipRule="evenodd" d="M45.3819 201.921H34.0364V179.23H45.3819C51.6478 179.23 56.7273 184.309 56.7273 190.575C56.7273 196.841 51.6478 201.921 45.3819 201.921Z" fill="#FF7262" />
                  </g>
                </g>
                <g id="svg-js-logo" className="logo-svg">
                  <path id="Vector_52" d="M456.153 189.247H388.296V257.104H456.153V189.247Z" fill="#F5DE19" />
                  <path id="Vector_53" d="M433.879 242.261C434.457 243.435 435.355 244.422 436.47 245.108C437.585 245.794 438.871 246.151 440.18 246.138C442.822 246.138 444.511 244.817 444.511 242.988C444.511 240.807 442.776 240.026 439.867 238.754L438.273 238.07C433.668 236.11 430.615 233.655 430.615 228.464C430.615 223.682 434.25 220.04 439.952 220.04C441.798 219.906 443.643 220.32 445.255 221.228C446.868 222.136 448.178 223.498 449.021 225.146L444.036 228.333C443.711 227.516 443.146 226.817 442.415 226.329C441.684 225.841 440.822 225.586 439.943 225.599C439.559 225.561 439.172 225.603 438.805 225.722C438.439 225.842 438.101 226.036 437.814 226.294C437.527 226.551 437.297 226.865 437.138 227.216C436.979 227.567 436.895 227.948 436.891 228.333C436.891 230.245 438.076 231.021 440.813 232.21L442.407 232.894C447.826 235.218 450.889 237.586 450.889 242.91C450.889 248.651 446.379 251.797 440.323 251.797C437.963 251.947 435.61 251.412 433.548 250.254C431.486 249.097 429.803 247.368 428.702 245.275L433.879 242.261ZM411.353 242.813C412.354 244.59 413.265 246.092 415.456 246.092C417.55 246.092 418.873 245.273 418.873 242.086V220.403H425.249V242.171C425.249 248.773 421.371 251.778 415.727 251.778C413.732 251.887 411.751 251.389 410.043 250.351C408.336 249.313 406.983 247.783 406.162 245.961L411.353 242.813Z" fill="black" />
                </g>
                <g id="svg-rails-logo" className="logo-svg" clipPath="url(#clip2_47_3)">
                  <g id="Group_5">
                    <path id="Vector_54" d="M31.3569 391.361C31.3569 391.361 33.7762 379.896 43.4704 367.067C53.1673 354.24 68.3036 345.37 79.8101 345.571C102.731 345.733 120.92 368.249 120.92 368.249C120.92 368.249 120.201 368.645 119.431 369.159C98.951 350.324 84.5307 356.617 80.0336 358.236C59.2773 367.063 62.2924 394.682 62.2924 394.682L31.3625 391.362L31.3569 391.361ZM100.998 350.065C99.7997 349.424 98.5969 348.821 97.3512 348.251L97.7851 345.678C100.173 346.65 101.103 347.226 101.433 347.458L100.995 350.067L100.998 350.065ZM96.3836 357.974C97.6283 358.187 98.9063 358.481 100.208 358.9L99.7912 361.311C98.5291 360.894 97.2535 360.602 95.966 360.422L96.3864 357.974L96.3836 357.974ZM83.2084 344.192C82.0751 344.071 80.9345 343.991 79.7856 343.988L79.2874 341.597C80.599 341.58 81.8603 341.637 83.0716 341.767L83.6004 344.24L83.2106 344.198L83.2084 344.192ZM83.5431 358.812C84.5637 358.488 85.752 358.176 87.122 357.929L87.7072 360.611C86.5282 360.837 85.3444 361.186 84.1498 361.61L83.5403 358.812L83.5431 358.812ZM66.8673 346.601C65.7932 347.004 64.75 347.487 63.6664 348.004L62.1592 345.068C63.2846 344.555 64.3592 344.121 65.3943 343.714L66.867 346.604L66.8673 346.601ZM73.0031 365.089C73.8013 364.265 74.722 363.454 75.7071 362.726L77.1864 365.58C76.2267 366.387 75.3502 367.205 74.5764 368.116L73.0034 365.086L73.0031 365.089ZM65.9688 377.093C66.4319 375.717 66.9441 374.305 67.6062 372.911L70.1844 375.447C69.6308 376.931 69.1883 378.43 68.8714 379.939L65.9661 377.092L65.9688 377.093ZM50.8133 356.648C49.7619 357.521 48.7518 358.483 47.814 359.453L45.0785 356.546C46.0831 355.662 47.1657 354.787 48.2897 353.919L50.8136 356.646L50.8133 356.648ZM37.1005 372.881C36.3214 374.262 35.291 376.095 34.6413 377.372L30.3124 375.245C30.8158 374.229 31.8504 372.357 32.775 370.75L37.1033 372.882L37.1005 372.881ZM64.3165 386.976L68.3796 388.956C68.4153 390.827 68.5818 392.582 68.771 394.152L64.2516 391.963C64.1828 390.767 64.1688 389.061 64.3134 386.978L64.3165 386.976Z" fill="#CC0000" />
                  </g>
                </g>
                <g id="svg-react-logo" className="logo-svg" clipPath="url(#clip3_47_3)">
                  <path id="Vector_55" d="M366.215 394.772C370.565 394.772 374.091 391.245 374.091 386.895C374.091 382.545 370.565 379.019 366.215 379.019C361.865 379.019 358.339 382.545 358.339 386.895C358.339 391.245 361.865 394.772 366.215 394.772Z" fill="#61DAFB" />
                  <path id="Vector_56" d="M379.664 394.647C384.815 385.372 388.25 375.816 389.212 368.08C390.174 360.344 388.585 355.063 384.794 353.397C381.004 351.731 375.322 353.817 368.998 359.197C362.675 364.577 356.228 372.809 351.076 382.084C345.923 391.358 342.487 400.915 341.523 408.652C340.559 416.39 342.147 421.674 345.936 423.342C349.725 425.01 355.406 422.927 361.729 417.549C368.052 412.172 374.499 403.941 379.653 394.667L379.664 394.647ZM352.777 394.667C357.931 403.941 364.378 412.172 370.701 417.549C377.024 422.927 382.705 425.01 386.494 423.342C390.283 421.674 391.87 416.39 390.907 408.652C389.943 400.915 386.507 391.358 381.354 382.084C376.202 372.809 369.755 364.577 363.431 359.197C357.108 353.817 351.426 351.731 347.636 353.397C343.845 355.063 342.256 360.344 343.218 368.08C344.18 375.816 347.615 385.372 352.766 394.647L352.777 394.667ZM366.226 402.418C376.844 402.417 387.025 400.78 394.532 397.866C402.038 394.953 406.254 391.002 406.253 386.882C406.251 382.763 402.033 378.812 394.524 375.9C387.016 372.987 376.833 371.351 366.215 371.351C355.597 371.351 345.414 372.987 337.906 375.9C330.397 378.812 326.178 382.763 326.177 386.882C326.176 391.002 330.392 394.953 337.898 397.866C345.404 400.78 355.586 402.417 366.204 402.418H366.226Z" stroke="#61DAFB" strokeWidth="5" />
                </g>
                <g id="svg-ruby-logo" className="logo-svg">
                  <path id="Vector_57" d="M235.271 462.431L236.494 423.289L225.49 447.705L196.724 467.026C208.914 465.561 222.534 463.946 235.274 462.436L235.271 462.431Z" fill="#A00403" />
                  <path id="Vector_58" d="M236.007 429.285L219.758 417.81L216.371 432.082C233.052 432.667 230.181 429.934 236.007 429.285ZM201.775 446.606L222.671 452.091L216.371 432.082L201.775 446.606ZM188.453 450.148C196.152 470.846 198.041 475.302 201.775 446.606L188.317 450.458L188.453 450.148ZM212.877 413.601L227.559 413.025L219.758 417.81C218.042 416.833 214.132 414.449 212.877 413.601ZM185.317 441.962L185.455 456.969L188.612 450.292L185.317 441.962ZM216.333 416.008C220.461 419.746 216.849 429.48 209.365 437.585C201.881 445.691 192.136 451.027 188.008 447.288C184.032 443.559 187.439 433.668 194.945 425.561C202.452 417.455 212.363 412.262 216.34 416.008L216.333 416.008Z" fill="#B11205" />
                  <path id="Vector_59" d="M201.774 446.606L222.217 452.113C215.293 459.281 202.065 466.231 196.721 467.026L201.774 446.606Z" fill="#9F0D02" />
                  <path id="Vector_60" d="M216.371 432.082L222.361 451.954C228.098 445.301 233.199 438.072 235.502 429.465L216.371 432.082ZM235.271 462.431L231 439.545L222.361 451.954L235.271 462.431Z" fill="#891102" />
                  <path id="Vector_61" d="M235.66 429.609C237.32 423.308 237.323 414.209 227.559 413.025L219.758 417.81L235.66 429.609Z" fill="#AA1401" />
                  <path id="Vector_62" d="M185.455 456.969C186.268 467.241 193.537 467.033 196.721 467.026L188.461 450.299L185.455 456.969Z" fill="#9E1209" />
                  <path id="Vector_63" d="M201.774 446.606L210.716 461.783C215.43 458.971 219.054 455.606 222.21 451.962L201.774 446.606Z" fill="#900E04" />
                  <path id="Vector_64" d="M188.461 450.299L187.933 464.126C190.201 467.046 193.234 467.048 196.403 466.739C193.857 461.254 188.756 450.133 188.461 450.299Z" fill="#8B1104" />
                </g>
                <g id="svg-css-logo" className="logo-svg">
                  <path id="Vector_65" d="M297.4 107.396L291.138 37.16H359.944L353.679 107.385L325.5 115.196L297.4 107.396Z" fill="#1572B6" />
                  <path id="Vector_66" d="M325.541 109.225L348.309 102.911L353.667 42.9013H325.541V109.225Z" fill="#33A9DC" />
                  <path id="Vector_67" d="M325.541 68.3485H336.941L337.726 59.5267H325.541V50.913H325.57H347.142L346.934 53.2243L344.816 76.9622H325.541V68.3485Z" fill="white" />
                  <path id="Vector_68" d="M325.593 90.7188L325.556 90.73L315.961 88.1399L315.348 81.269H310.688H306.701L307.908 94.7953L325.552 99.693L325.593 99.6819V90.7188Z" fill="#EBEBEB" />
                  <path id="Vector_69" d="M336.206 76.598L335.169 88.1362L325.563 90.7263V99.6893L343.222 94.7953L343.352 93.3386L344.849 76.598H336.206Z" fill="white" />
                  <path id="Vector_70" d="M325.571 50.913V56.264V59.5081V59.5267H304.791H304.765L304.59 57.5907L304.2 53.2243L303.992 50.913H325.571Z" fill="#EBEBEB" />
                  <path id="Vector_71" d="M325.541 68.3485V73.6995V76.9436V76.9659H316.095H316.069L315.898 75.0299L315.504 70.6598L315.3 68.3485H325.541Z" fill="#EBEBEB" />
                </g>
              </g>
            </g>
            <defs>
              <clipPath id="clip0_47_3">
                <rect width="257.643" height="252.473" fill="white" transform="matrix(-0.989939 -0.141498 -0.141498 0.989939 372.109 133.93)" />
              </clipPath>
              <clipPath id="clip1_47_3">
                <rect width="68.0729" height="68.0729" fill="white" transform="translate(0 179.23)" />
              </clipPath>
              <clipPath id="clip2_47_3">
                <rect width="89.615" height="89.615" fill="white" transform="translate(36.2761 319.684) rotate(6.12641)" />
              </clipPath>
              <clipPath id="clip3_47_3">
                <rect width="112.019" height="112.019" fill="white" transform="translate(310.206 330.886)" />
              </clipPath>
            </defs>
          </svg>
        </div>

        <div className="col-12 col-xxl-9">
          <div className="text-center">

            <div className="project my-3 m-0 m-sm-2">
              <img className="project-img img-fluid" src={projectImage1} alt="screenshot of client website" />
              <div className="project-overlay project-blur d-flex flex-column justify-content-center p-3">
                <b>client website</b>
                <p>website designed and built by me in Wix</p>
                <a className="align-self-center" href="https://www.seijichampollion.com/" target="_blank" rel="noreferrer noopener">site</a>
              </div>
            </div>

            <div className="project my-3 m-0 m-sm-2">
              <img className="project-img" src={projectImage2} alt="screenshot of twitter clone" />
              <div className="project-overlay project-blur d-flex flex-column justify-content-center p-3">
                <b>Twitter Clone</b>
                <p>full stack twitter clone - a responsive react rails project utilizing AWS S3</p>
                <div className="project-links d-flex flex-row justify-content-center">
                  <a href="https://github.com/jonars99/full-stack-twitter-clone" target="_blank" rel="noreferrer noopener" className="mx-2">code</a>
                </div>
              </div>
            </div>

            <div className="project my-3 m-0 m-sm-2">
              <img className="project-img" src={projectImage3} alt="screenshot of accurrency web app" />
              <div className="project-overlay project-blur d-flex flex-column justify-content-center p-3">
                <b>accurrency</b>
                <p>responsive currency converter and exchange rates web app made with react and fetch</p>
                <div className="project-links d-flex flex-row justify-content-center">
                  <a href="https://github.com/jonars99/accurrency" target="_blank" rel="noreferrer noopener" className="mx-2">code</a>
                  <a href="https://accurrency-jr.netlify.app/" target="_blank" rel="noreferrer noopener" className="mx-2">site</a>
                </div>
              </div>
            </div>

            <div className="project my-3 m-0 m-sm-2">
              <img className="project-img" src={projectImage4} alt="screenshot of to do list web app" />
              <div className="project-overlay project-blur d-flex flex-column justify-content-center p-3">
                <b>To Do List</b>
                <p>to do list web app using react and fetch with altcademy's API</p>
                <div className="project-links d-flex flex-row justify-content-center">
                  <a href="https://github.com/jonars99/fswd-todolist" target="_blank" rel="noreferrer noopener" className="mx-2">code</a>
                  <a href="https://my-to-do-list-jr.netlify.app/" target="_blank" rel="noreferrer noopener" className="mx-2">site</a>
                </div>
              </div>
            </div>

            <p className="project-note text-center my-1">check out more projects on my <a className="github-note fw-bold" href="https://github.com/jonars99" target="_blank" rel="noreferrer noopener">GitHub</a></p>

          </div>
        </div>

      </div>
    </div>
  )
}

export default Projects;