import React from 'react';

const currentYear = new Date().getFullYear();

function Footer () {
  return (
    <footer id="footer" className="d-flex justify-content-around align-items-center">
      <p className="copyright px-3 py-2 m-0">&copy; {currentYear} Joanna Redihough</p>
      <p className="m-0">icons by <a href="https://www.svgrepo.com" target="_blank" rel="noreferrer noopener">svgrepo.com</a></p>
    </footer>
  )
}

export default Footer;